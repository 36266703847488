<template>
  <div id="vip">
    <div class="admin_main_block">
      <div class="user_temp_title">升级VIP</div>
      <div class="admin_table_main">
        <div class="vip_type">
          你的会员级别： {{ $store.state.userInfo.level }}
        </div>
        <div class="vip_type2">
          *本价格具有一定实时性，随快递价格会有一定范围波动。
        </div>
        <div class="storage_vip_list clearfix">
          <span
            @click="(sroreId = item.id), getExpressSheet(item.id)"
            :class="sroreId == item.id ? 'red' : ''"
            v-for="item in storeList"
            >{{ item.name }}</span
          >
        </div>
        <div class="storage_vip_list clearfix">
          <span
            @click="(expressSheetId = item.id), getVipLevel(item.id)"
            :class="expressSheetId == item.id ? 'red' : ''"
            v-for="item in expressSheetList"
            >{{ item.shipper_name }}({{
              expressSheetNameList[item.express_status]
            }})</span
          >
        </div>
        <table
          border="0"
          align="center"
          cellpadding="0"
          cellspacing="0"
          v-loading="loading"
        >
          <tbody>
            <tr>
              <td></td>
              <td v-for="item in vipList">{{ item.name }}</td>
            </tr>
            <tr v-for="(item, key) in expressFee">
              <td>
                {{ key == 0 ? 0 : expressFee[key - 1].max_weight }}kg~{{
                  item.max_weight
                }}kg
              </td>
              <td v-for="item2 in vipList">
                {{ (parseFloat(item.cost) + parseFloat(item.profit) - parseFloat(item2.discount)) }} 元
              </td>
            </tr>
            <tr>
              <td>续重 / 1kg</td>
              <td v-for="item in vipList">{{ item.rise}} 元</td>
            </tr>
            <!-- <tr>
                            <td>0.5kg~1kg</td>
                            <td> 3.3 元 </td>
                            <td>3.1元</td>
                        </tr>
                        <tr>
                            <td>续重 / 1kg	</td>
                            <td> 1元 </td>
                            <td>1元</td>
                        </tr>-->
            <!-- <tr> 
                            <td>升级费用</td>
                            <td v-for="item in vipList"> {{item.upgradeAmount}} 元 </td>
                        </tr> -->
            <!-- <tr v-if="$store.state.tenantInfo.id==1" style="color:red"> 
                            <td>升级方式</td>
                            <td v-for="item in vipList" v-if="item.conditionNum>0" >方法一：推荐五位商家入驻 <span style="cursor: pointer;color: #3454af;" @click="copy">(点击复制专属链接)</span></td>
                            <td v-for="item in vipList" v-if="item.conditionNum>0" >方法二：单笔充值1000自动升级 <span style="cursor: pointer;color: #3454af;" @click="recharge">(点击跳转充值页面)</span></td>
                             <td v-for="item in vipList">推荐{{item.conditionNum}}个商家注册即自动升级{{item.name}}</td> 
                        </tr>-->
            <tr>
              <td>升级方式</td>
              <td
                v-for="item in vipList"
                :style="item.condition_num > 0 ? 'color: red;' : ''"
              >
                推荐{{ item.condition_num }}个商家注册
              </td>
              <!-- <td v-for="item in vipList">推荐{{item.conditionNum}}个商家注册即自动升级{{item.name}}</td> -->
            </tr>
          </tbody>
        </table>
        <br />
        <!-- <div class="lastdiv" v-for="item in vipList" v-if="item.upgradeAmount>nowVip.upgradeAmount" @click="upgrade(item)">升级{{item.name}}</div> -->
        <div
          class="lastdiv"
          @click="$router.push('/member/inviter')"
          v-if="$store.state.tenantInfo.id == 1"
        >
          前往邀请好友（推荐{{ vipList.condition_num }}个商家即可升级VIP）
        </div>
        <div class="lastdiv" @click="$router.push('/member/inviter')" v-else>
          前往邀请好友
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      storeList: [], //仓库列表
      sroreId: "",
      expressSheetList: [], //面单列表
      expressSheetId: "",
      expressFee: [], //快递费用
      vipList: [], //等级列表
      expressSheetNameList: [
        "其他面单",
        "通用面单",
        "菜鸟面单",
        "拼多多面单",
        "京东面单",
      ],
      nowVip: "", //当前vip等级
      loading: false, //防重复点击
      aboutShow: 1,
    };
  },
  created() {
    this.getStoreList();
    if ("www.fabwang.com;fab.fabwang.com".indexOf(window.location.host) != -1) {
      this.aboutShow = 2;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    copy() {
      this.$request.post({
        url: "/ReportApi/business/getInviteCode",
        params: { id: localStorage.getItem("ids")},
        success: (res) => {
          var inviteUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            "/?inviteCode=" +
            res;
          this.$common.copy(inviteUrl);
        },
        finally: () => {},
      });
    },
    recharge() {
      this.$router.push("/member/recharge");
    },
    //查看全部仓库
    getStoreList() {
      this.$request.post({
        url: "/ReportApi/business/getstore",
        params: {},
        success: (res) => {
          this.storeList = res;
          if (this.storeList.length > 0) {
            this.sroreId = this.storeList[0].id;
            this.getExpressSheet(this.sroreId);
          }
        },
      });
    },
    //通过仓库id查面单信息
    getExpressSheet(storeId) {
      this.$request.post({
        url: "/ReportApi/business/expresssheet",
        params: {
          storeId: storeId,
        },
        success: (res) => {
          this.expressSheetList = res;
          if (this.expressSheetList.length > 0) {
            this.expressSheetId = this.expressSheetList[0].id;
            this.getVipLevel(this.expressSheetId);
          }
        },
      });
    },
    //通过面单信息查会员等级列表
    getVipLevel(expressSheetId) {
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/getexpressfree",
        params: {
          expressSheetId: expressSheetId,
          tenantId: this.$store.state.userInfo.info.tenant_id,
          level: this.$store.state.userInfo.level,
        },
        success: (res) => {
          this.expressFee = res.list;
          this.vipList = res.viplist;
          if (!this.nowVip) {
            var i = 1;
            res.viplist.forEach((item) => {
              if (this.$store.state.userInfo.info.levelId == item.id) {
                i = 2;
                this.nowVip = item;
              }
            });
            if (i == 1) {
              // this.getDetail(this.$store.state.userInfo.info.levelId);
            }
          }
        },
        finally: () => {
          this.loading = false;
        },
      });
    },
    //自定义会员则通过id继续查询
    // getDetail(id) {
    //   this.$request.post({
    //     url: "/bus/vipLevel/detail",
    //     params: {
    //       id: id,
    //     },
    //     success: (res) => {
    //       this.vipList.push(res);
    //       this.nowVip = res;
    //     },
    //     finally: () => {},
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
#vip {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    min-height: 855px;
    border-radius: 4px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .admin_table_main {
      .vip_type {
        margin-left: 50px;
        line-height: 60px;
        font-size: 18px;
      }
      .vip_type2 {
        margin-left: 50px;
        line-height: 20px;
        font-size: 14px;
        color: #f80;
        margin-bottom: 10px;
      }
      .storage_vip_list {
        margin-left: 50px;
        margin-bottom: 10px;
        span {
          padding: 0 10px;
          min-width: 165px;
          line-height: 40px;
          border: 1px solid #dedede;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 5px;
          display: block;
          height: 40px;
          text-align: center;
          color: #666;
          float: left;
          cursor: pointer;
          &.red {
            color: white;
            background-color: #f80;
            border-color: #f80;
          }
        }
      }
      table {
        margin: 10px auto 0;
        font-size: 14px;
        width: 880px;
        color: rgb(119, 119, 119);
        border-left: 1px solid rgb(222, 222, 222);
        tbody {
          tr {
            &:first-child {
              background: #f80;
              color: white;
              td {
                border-right: none;
                height: 35px;
                &:nth-child(3) {
                  color: white;
                }
              }
            }
            td {
              height: 40px;
              text-align: center;
              border-bottom: 1px solid rgb(222, 222, 222);
              border-right: 1px solid rgb(222, 222, 222);
              // &:nth-child(3){
              //     color: #f80;
              // }
            }
          }
        }
      }
      .lastdiv {
        margin-left: 50px;
        background: #f80;
        color: white;
        line-height: 40px;
        text-align: center;
        width: 880px;
        border-radius: 4px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>